import { FormControl, FormHelperText, makeStyles, OutlinedInput } from "@material-ui/core";
import {React} from 'react';


const useStyles = makeStyles((theme) => ({
    formTextInputWrapper: {
        width: '100%'
    },
    formTextInput: {
        '& > input': {
            padding: '10px 25px'
        },
        '&::placeholder': {
            color: '#666666',
            opacity: 1,
        },
        '& > input::placeholder': {
            color: '#666666',
            opacity: 1,
        },
        '& > p': {
            color: '#666666',
            opacity: 1,
        },
        width: '100%',
        borderRadius: 50,
        fontSize: 24,
    },
    errorText: {
        color: '#B30900 !important',
    }
}));

const BookingFormTextInput = ({
    error,
    helperText,
    green,
    title = "",
    ...rest
}) => {

    const classes = useStyles();

    return (
        <FormControl variant="outlined" className={classes.formTextInputWrapper}>
            <OutlinedInput 
                className={classes.formTextInput}
                error={error}
                inputProps={{
                    title: title
                }}
                {...rest}
            />
            {
                error && <FormHelperText error className={classes.errorText}>
                    {helperText}
                </FormHelperText>
            }
        </FormControl>
    );
}

export default BookingFormTextInput;