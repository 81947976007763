import { Radio } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import {React} from 'react';

const StyledRadio = withStyles({
    root: {
      color: '#22A28D',
      '&$checked': {
        color: '#22A28D',
      },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export default StyledRadio;